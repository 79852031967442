import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useParams } from "react-router-dom";

import "./App.css";
import { useAuth } from "./firebase/authContext";

import Login from "./pages/Login";
import Layout from "./pages/Layout";
import UserPage from "./pages/UserPage";
import VerificationPage from "./pages/VerificationPage";
import RejectedPage from "./pages/RejectedPage";
import UserProfilePage from "./pages/UserProfilePage";
import UsersContent from "./pages/UsersContent";
import DeletionRequestsPage from "./pages/DeletionRequests";
import AdminPage from "./pages/AdminPage";
import RidesPage from "./pages/RidesPage";
import RidesListPage from "./pages/RidesListPage";
import RideDetailPage from "./pages/RideDetailPage";
import MapActiveRides from "./pages/MapActiveRides";
import InviteCodesPage from "./pages/InviteCodesPage";
import GroupLists from "./pages/GroupLists";
import GroupDetails from "./pages/GroupDetails";

function App() {
  const { currentUser } = useAuth();
  // console.log(JSON.stringify(currentUser));

  const pages = [
    // { path: "/login", component: <Login />, layout: false },
    {
      path: "/dashboard/usersTable",
      component: <UserPage />,
      tabName: "UsersTable",
    },
    {
      path: "/dashboard/verifications",
      component: <VerificationPage />,
      tabName: "ID Verification TTT",
    },
    { path: "/dashboard/users", component: <UsersContent />, tabName: "Users" },
    {
      path: "/dashboard/users/:uid",
      component: <UserProfileWithUid />,
      layout: false,
    },
    {
      path: "/dashboard/rejections",
      component: <RejectedPage />,
      tabName: "Rejections",
    },
    {
      path: "/dashboard/administrators",
      component: <AdminPage />,
      tabName: "Administrators",
    },
    {
      path: "/dashboard/deletion-requests",
      component: <DeletionRequestsPage />,
      tabName: "Deletion Requests",
    },
    { path: "/dashboard/rides", component: <RidesPage />, tabName: "Rides" },
    {
      path: "/dashboard/ridesList",
      component: <RidesListPage />,
      tabName: "List of Rides",
    },
    {
      path: "/dashboard/rides/:rideID",
      component: <RideDetailWithRideID />,
      tabName: "List of Rides",
    },
    {
      path: "/dashboard/rides/activeRidesMap",
      component: <MapActiveRides />,
      tabName: "Active Rides Map",
    },
    {
      path: "/dashboard/users/codes",
      component: <InviteCodesPage />,
      tabName: "Invite Codes",
    },
    {
      path: "/details/groupsList",
      component: <GroupLists />,
      tabName: "List of Groups",
    },
    {
      path: "/details/groups/:groupId",
      component: <GroupDetails />,
      tabName: "List of Groups",
    },
  ];

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route key="/login" path="/login" element={<Login />} />
          {pages.map(({ path, component, layout, tabName }) => (
            <Route
              key={path}
              path={path}
              element={
                currentUser ? (
                  layout !== false ? (
                    <Layout
                      tabName={tabName}
                      userName={currentUser.name ?? "NO NAME"}
                    >
                      {component}
                    </Layout>
                  ) : (
                    component
                  )
                ) : (
                  <Navigate replace to="/login" />
                )
              }
            />
          ))}
          <Route
            path="*"
            element={
              <Navigate
                replace
                to={currentUser ? "/dashboard/verifications" : "/login"}
              />
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

function UserProfileWithUid() {
  const { uid } = useParams();
  const { currentUser } = useAuth();

  return (
    <Layout tabName={`User: ${uid}`} userName={currentUser.name ?? "NO NAME"}>
      <UserProfilePage />
    </Layout>
  );
}

function RideDetailWithRideID() {
  const { rideID } = useParams();
  const { currentUser } = useAuth();

  return (
    <Layout
      tabName={`Ride: ${rideID}`}
      userName={currentUser.name ?? "NO NAME"}
    >
      <RideDetailPage />
    </Layout>
  );
}

export default App;
