import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
  writeBatch,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { listAll, deleteObject } from "firebase/storage";
import { storage } from "./config";
import { sendNotification } from "./notificationFunctions";

const db = getFirestore();

export const removeNoIdUser = async (uid) => {
  try {
    const batch = writeBatch(db);
    
    // Update private_info/id_status to Missing
    const userPrivateInfoRef = doc(db, "users", uid, "private_info", "info");
    batch.update(userPrivateInfoRef, { id_status: "Missing" });

    // Remove from newUsersUnverified collection
    const newUsersRef = doc(db, "newUsersUnverified", uid);
    batch.delete(newUsersRef);

    // Commit all changes
    await batch.commit();

    // Send notification to user
    const notificationPayload = {
      userId: uid,
      title: "ID Verification Status",
      body: "Your account has been marked as missing ID verification. Please submit your ID to continue using the service.",
      highPriority: true,
      data: { action: "OPEN_ID_REUPLOAD_PAGE", status: "Missing" },
    };
    await sendNotification(notificationPayload);

    return true;
  } catch (error) {
    console.error("Error removing user with no ID:", error);
    return false;
  }
};

export const getUsers = async () => {
  try {
    const usersSnapshot = await getDocs(collection(db, "users"));
    return usersSnapshot.docs.map((doc) => ({
      uid: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching users:", error);
    return [];
  }
};

export const getNewUsers = async () => {
  try {
    const newUsersSnapshot = await getDocs(
      collection(db, "newUsersUnverified")
    );
    return newUsersSnapshot.docs.map((doc) => doc.id);
  } catch (error) {
    console.error("Error fetching new users:", error);
    return [];
  }
};

export const getKeyValuesfromDB = async (node) => {
  try {
    const newUsersSnapshot = await getDocs(collection(db, node));
    const newUsersData = newUsersSnapshot.docs.reduce((acc, doc) => {
      acc[doc.id] = doc.data();
      return acc;
    }, {});
    return newUsersData;
  } catch (error) {
    console.error("Error fetching values and keys", error);
    return [];
  }
};

export const getDataFromUid = async (uid) => {
  try {
    const userData = {};

    // Fetch subcollections
    const privateInfoDoc = await getDoc(
      doc(db, "users", uid, "private_info", "info")
    );
    const protectedInfoDoc = await getDoc(
      doc(db, "users", uid, "protected_info", "info")
    );
    const publicInfoDoc = await getDoc(
      doc(db, "users", uid, "public_info", "info")
    );

    if (privateInfoDoc.exists()) {
      userData.private_info = privateInfoDoc.data();
    }

    if (protectedInfoDoc.exists()) {
      userData.protected_info = protectedInfoDoc.data();
    }

    if (publicInfoDoc.exists()) {
      userData.public_info = publicInfoDoc.data();
    }

    return userData;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const verifyUser = async (uid, status, reason = null) => {
  try {
    const batch = writeBatch(db);
    
    // Update private_info/id_status
    const userPrivateInfoRef = doc(db, "users", uid, "private_info", "info");
    batch.update(userPrivateInfoRef, { id_status: status });

    // Remove from newUsersUnverified collection
    const newUsersRef = doc(db, "newUsersUnverified", uid);
    batch.delete(newUsersRef);

    // If rejected, add to rejectedUsers collection
    if (status === "Rejected") {
      const rejectedUsersRef = doc(db, "rejectedUsers", uid);
      batch.set(rejectedUsersRef, { 
        uid,
        reason,
        timestamp: new Date().toISOString()
      });
    }

    // Commit all changes
    await batch.commit();

    // Send notification to user
    const notificationPayload = {
      userId: uid,
      title: "ID Verification Status",
      body: status === "Verified" 
        ? "Your ID has been verified! You can now use all features of the app."
        : status === "Rejected"
        ? `Your ID verification was rejected. Reason: ${reason}`
        : status === "Flagged"
        ? `Your account has been flagged. Reason: ${reason}`
        : "Your ID verification status has been updated.",
      highPriority: true,
      data: { action: "OPEN_ID_STATUS_PAGE", status },
    };
    await sendNotification(notificationPayload);

    return true;
  } catch (error) {
    console.error("Error verifying user:", error);
    return false;
  }
};

export const requestDeleteUser = async (uid) => {
  try {
    await setDoc(doc(db, "accountsDeletionRequests", uid), {
      requestedBy: "admin",
    });
    console.log(`Deletion request for user ${uid} has been added.`);
  } catch (error) {
    console.error("Error requesting user deletion:", error);
  }
};

export const cancelDeleteRequest = async (uid) => {
  try {
    await deleteDoc(doc(db, "accountsDeletionRequests", uid));
  } catch (error) {
    console.error("Error cancelling deletion request:", error);
  }
};

export const removeFromRejectedUsers = async (uid) => {
  try {
    await deleteDoc(doc(db, "rejectedUsers", uid));
  } catch (error) {
    console.error("Error removing from rejected users:", error);
  }
};

export const getDeletionRequests = async () => {
  try {
    const requestsSnapshot = await getDocs(
      collection(db, "accountsDeletionRequests")
    );
    return requestsSnapshot.docs.reduce((acc, doc) => {
      acc[doc.id] = doc.data().requestedBy;
      return acc;
    }, {});
  } catch (error) {
    console.error("Error fetching deletion requests:", error);
    return {};
  }
};

const deleteAllFilesInFolder = async (folderRef) => {
  const listResult = await listAll(folderRef);

  // Delete all files
  const deletePromises = listResult.items.map((fileRef) =>
    deleteObject(fileRef)
  );

  // Recursively delete all subfolders
  const subfolderDeletePromises = listResult.prefixes.map((subfolderRef) =>
    deleteAllFilesInFolder(subfolderRef)
  );

  await Promise.all([...deletePromises, ...subfolderDeletePromises]);
};

export const deleteUserFromStorage = async (uid) => {
  try {
    const userFolderRef = storage.ref().child(`users/${uid}`);
    await deleteAllFilesInFolder(userFolderRef);
    console.log(
      `Successfully deleted all files and folders for user with uid: ${uid}`
    );
  } catch (error) {
    console.error("Error deleting user from storage:", error);
  }
};

export const deleteUserFromAuth = async (uid) => {
  const functions = getFunctions(undefined, "europe-west2");
  const deleteUser = httpsCallable(functions, "deleteUserFromAuth");

  try {
    const result = await deleteUser({ uid });
    console.log(result.data.message);
    return true;
  } catch (error) {
    console.error("Error deleting user from Auth:", error);
    return false;
  }
};

export const deleteUserFromDB = async (uid) => {
  try {
    await deleteDoc(doc(db, "users", uid));
  } catch (error) {
    console.error("Error deleting user from database:", error);
  }
};

export const addToDB = async (node, data) => {
  try {
    const docRef = doc(collection(db, node));
    await setDoc(docRef, data);
    return true;
  } catch (error) {
    console.error("Error adding data to database:", error);
    return false;
  }
};

export const getGroups = async () => {
  try {
    const groupsSnapshot = await getDocs(collection(db, "rides_groups"));
    return groupsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching groups:", error);
    return [];
  }
};

export const getGroupById = async (groupId) => {
  try {
    const groupDoc = await getDoc(doc(db, "rides_groups", groupId));

    if (!groupDoc.exists()) {
      throw new Error("Group not found");
    }

    return {
      id: groupId,
      ...groupDoc.data(),
    };
  } catch (error) {
    console.error("Error fetching group details:", error);
    throw error;
  }
};
