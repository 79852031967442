import React, { useEffect, useState, useCallback } from "react";
import NavBar from "../components/NavBar";
import { verifyUser, removeNoIdUser } from "../firebase/dbFunctions";
import { getDataFromUid, getNewUsers } from "../firebase/userFunctions";

// Debounce utility function
const debounce = (func, delay) => {
  let debounceTimer;
  return function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), delay);
  };
};

const VerificationPage = () => {
  const [userDatas, setUserDatas] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchNewUsers = async () => {
      try {
        const newUsersData = await getNewUsers();
        const userDataPromises = newUsersData.map(
          (uid) => getDataFromUid(uid).catch(() => null) // Handle error for individual user fetch
        );
        const userDataArray = await Promise.all(userDataPromises);

        const validUserDataArray = userDataArray.filter(
          (userData) => userData && userData.private_info
        );
        validUserDataArray.sort(
          (a, b) =>
            new Date(b.private_info.dateJoined) -
            new Date(a.private_info.dateJoined)
        );
        setUserDatas(validUserDataArray);
      } catch (error) {
        console.error("Error fetching new users:", error);
      }
    };

    fetchNewUsers();
  }, []);

  const showNextUser = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % userDatas.length);
  }, [userDatas.length]);

  const showPreviousUser = useCallback(() => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + userDatas.length) % userDatas.length
    );
  }, [userDatas.length]);

  const handleApprove = useCallback(
    async (userId) => {
      try {
        setUserDatas((prevUserDatas) =>
          prevUserDatas.filter(
            (userData) => userData.private_info.uid !== userId
          )
        );

        await verifyUser(userId, "Verified");
        showNextUser();
      } catch (error) {
        console.error("Error approving user:", error);
      }
    },
    [showNextUser]
  );

  const handleReject = useCallback(
    async (userId) => {
      const reason = prompt(
        "Please enter a reason for rejecting this user (Name, Sex, Face, ID not visible):"
      );
      if (reason) {
        try {
          setUserDatas((prevUserDatas) =>
            prevUserDatas.filter(
              (userData) => userData.private_info.uid !== userId
            )
          );

          await verifyUser(userId, "Rejected", reason);
          showNextUser();
        } catch (error) {
          console.error("Error rejecting user:", error);
        }
      }
    },
    [showNextUser]
  );

  const handleFlag = useCallback(
    async (userId) => {
      const reason = prompt("Please enter a reason for flagging this user:");
      if (reason) {
        try {
          setUserDatas((prevUserDatas) =>
            prevUserDatas.filter(
              (userData) => userData.private_info.uid !== userId
            )
          );
          await verifyUser(userId, "Flagged", reason);
          showNextUser();
        } catch (error) {
          console.error("Error flagging user:", error);
        }
      }
    },
    [showNextUser]
  );

  const handleNoIdSubmitted = useCallback(
    async (userId) => {
      const confirmed = window.confirm(
        "Are you sure you want to mark this user as missing ID verification? This will remove them from the verification queue."
      );
      if (confirmed) {
        try {
          setUserDatas((prevUserDatas) =>
            prevUserDatas.filter(
              (userData) => userData.private_info.uid !== userId
            )
          );
          await removeNoIdUser(userId);
          showNextUser();
        } catch (error) {
          console.error("Error removing user:", error);
        }
      }
    },
    [showNextUser]
  );

  // useEffect(() => {
  //   const handleKeyPress = (event) => {
  //     if (!userDatas.length) return;

  //     // if (event.key === "a") {
  //     //   handleApprove(userDatas[currentIndex].private_info.uid);
  //     // } else if (event.key === "r") {
  //     //   handleReject(userDatas[currentIndex].private_info.uid);
  //     // } else if (event.key === "f") {
  //     //   handleFlag(userDatas[currentIndex].private_info.uid);
  //     // } else if (event.key === "n") {
  //     //   handleNoIdSubmitted(userDatas[currentIndex].private_info.uid);
  //     // } else if (event.key === "ArrowRight") {
  //     //   showNextUser();
  //     // } else if (event.key === "ArrowLeft") {
  //     //   showPreviousUser();
  //     // }
  //   };

  //   const debouncedHandleKeyPress = debounce(handleKeyPress, 300);

  //   window.addEventListener("keydown", debouncedHandleKeyPress);

  //   return () => {
  //     window.removeEventListener("keydown", debouncedHandleKeyPress);
  //   };
  // }, [
  //   currentIndex,
  //   userDatas,
  //   handleApprove,
  //   handleReject,
  //   handleFlag,
  //   handleNoIdSubmitted,
  //   showNextUser,
  //   showPreviousUser,
  // ]);

  const [isPopupVisible, setPopupVisible] = useState(false);
  const [popupImages, setPopupImages] = useState({ profile: "", id: "" });

  const handleImageClick = useCallback((profileImage, idImage) => {
    setPopupImages({ profile: profileImage, id: idImage });
    setPopupVisible(true);
  }, []);

  const closePopup = useCallback(() => {
    setPopupVisible(false);
  }, []);

  return (
    <div className="flex h-screen bg-gray-100">
      <NavBar />
      <div className="flex-grow my-4 overflow-y-auto">
        <h1 className="text-2xl font-bold mb-4">
          New Users ({userDatas.length})
        </h1>
        {userDatas.length === 0 ? (
          <p>No new users</p>
        ) : (
          <div className="flex flex-col h-full space-y-4 items-center">
            {userDatas[currentIndex] && (
              <div
                key={userDatas[currentIndex].private_info.uid}
                className="bg-white p-4 rounded shadow mb-4 flex flex-col items-center"
              >
                <div
                  className="flex items-center mb-4 cursor-pointer"
                  onClick={() =>
                    handleImageClick(
                      userDatas[currentIndex].public_info.profile_picture_url,
                      userDatas[currentIndex].private_info.id_url
                    )
                  }
                >
                  <img
                    src={
                      userDatas[currentIndex].public_info.profile_picture_url
                    }
                    alt="Profile"
                    className="h-60 w-60 rounded-full mr-4 object-cover"
                  />
                  <img
                    src={userDatas[currentIndex].private_info.id_url}
                    alt="ID"
                    className="h-60 w-60 mr-4 object-cover"
                  />
                </div>
                <div className="text-center">
                  <h2 className="text-lg font-semibold">{`${userDatas[currentIndex].private_info.first_name} ${userDatas[currentIndex].private_info.last_name}`}</h2>
                  <p className="text-sm text-gray-500">{`DOB: ${userDatas[currentIndex].private_info.DOBtext}`}</p>
                  <p className="text-sm text-gray-500">{`ID Mismatch?: ${userDatas[currentIndex].private_info.idMismatch}`}</p>
                  <p className="text-sm text-gray-500">{`Gender: ${userDatas[currentIndex].private_info.gender}`}</p>
                  <p className="text-sm text-gray-500">{`UID: ${userDatas[currentIndex].private_info.uid}`}</p>
                  <p className="text-sm text-gray-500">{`Date Joined: ${userDatas[currentIndex].private_info.dateJoined}`}</p>
                </div>
                <div className="mt-4 flex space-x-4">
                  <button
                    className="bg-green-500 text-white px-4 py-2 rounded"
                    onClick={() =>
                      handleApprove(userDatas[currentIndex].private_info.uid)
                    }
                  >
                    Approve (A)
                  </button>
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded"
                    onClick={() =>
                      handleReject(userDatas[currentIndex].private_info.uid)
                    }
                  >
                    Reject (R)
                  </button>
                  <button
                    className="bg-yellow-500 text-white px-4 py-2 rounded"
                    onClick={() =>
                      handleFlag(userDatas[currentIndex].private_info.uid)
                    }
                  >
                    Flag (F)
                  </button>
                  <button
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                    onClick={() =>
                      handleNoIdSubmitted(
                        userDatas[currentIndex].private_info.uid
                      )
                    }
                  >
                    No ID Submitted (N)
                  </button>
                  <button
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                    onClick={showPreviousUser}
                  >
                    Previous User (←)
                  </button>
                  <button
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                    onClick={showNextUser}
                  >
                    Next User (→)
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {isPopupVisible && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={closePopup}
        >
          <div className="bg-white p-4 rounded max-w-4xl">
            <div className="flex space-x-4">
              <img
                src={popupImages.profile}
                alt="Profile"
                className="max-h-[80vh] object-contain"
              />
              <img
                src={popupImages.id}
                alt="ID"
                className="max-h-[80vh] object-contain"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VerificationPage;
